@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap');


body {
    background-color: #ffffff;
    padding: 5px;
    padding-left: 20px;
    font-family: "Manrope", sans-serif;
}
a {
    color:black;
    font-size: 20px;
}
h1 {
    color: black;
    text-align: left;
    font-size: 60px;
    padding: 2px;
}
.top{
    display:flex;
    flex-direction:row;
    justify-content: space-evenly 
}
.contacts{
    font-size: 25px;
    display:flex;
    flex-direction:column;
    padding-left:10px;
}
.contacttitle{
    color:#8d0092;
    font-size: 75px;
    font-weight:bold;
    margin:0px;
    padding:15px;
}
.link{
    text-decoration:none;
    font-size:25px;
    white-space: nowrap; /* Prevent line breaks */
      overflow: hidden; 
      height:30px;
}

.link:hover{
    text-decoration:underline;
}
.contact{
    padding: 5px;
    font-family:"Manrope", sans-serif;
    font-size:20px;
}
hr{
    margin-top: 40px;
    color:black;
    height: 5px;
    background-color: black;
}



.contactback {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index:-3;
    opacity:0.7;
}


.links {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 18px;
    font-family: sans-serif;
}
a {
    text-decoration: none;
    color: black;
    margin-left: 1em;
}
a:hover {
    text-decoration: underline;
}
a img.icon {
    display: inline-block;
    height: 1em;
    margin: 0 0 -0.1em 0.3em;
}


            @keyframes rotate {
					 0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
            .out-bottom {
                animation: rotate 25s linear infinite;
                transform-origin: 84px 93px;
            }
            .in-bottom {
                animation: rotate 15s linear infinite;
                transform-origin: 84px 93px;
            }
