@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap');


body {
    background-color: #ffffff;
    padding: 5px;
    padding-left: 20px;
    font-family: "Manrope", sans-serif
}

h1 {
    color: black;
    text-align: left;
    font-family: "Manrope", sans-serif
}
.abouttitle{
    font-family: "Manrope", sans-serif;
    font-size: 75px;
    color: #ff0062;
    font-weight:bold;
    margin:0px;
    padding-top:20px;
}
.link{
    text-decoration:none;
    font-size:25px;
    white-space: nowrap; /* Prevent line breaks */
      overflow: hidden; 
      height:30px;
}

.link:hover{
    text-decoration:underline;
}
.top{
    display:flex;
    flex-direction:row;
    justify-content: space-evenly;
}
.pics{
    display:flex;
    flex-direction: row;
    padding-top:20px;
    justify-content: space-evenly;
}

.pic{
    height:260px;
}
.abt{
    color: black;
    font-family: 'Manrope', sans-serif;
    text-align: left;
    font-size: 20px !important;
    line-height: 1.2;
    text-indent: 20px;
    margin-right: 20px;
    margin-left:20px;
    margin-top:0;
    padding:0;
}
.inlink{
    font-size:20px;
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
    font-weight: bold;
}
hr{
    margin-top: 40px;
    color:black;
    height: 5px;
    background-color: black;
}

body {
    background-color: white;
}
.abtback {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index:-3;
    opacity:0.5;
}


.links {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 18px;
    font-family: sans-serif;
}
a {
    text-decoration: none;
    color: black;
    margin-left: 1em;
}
a:hover {
    text-decoration: underline;
}
a img.icon {
    display: inline-block;
    height: 1em;
    margin: 0 0 -0.1em 0.3em;
}


            @keyframes rotate {
					 0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
            .out-bottom {
                animation: rotate 25s linear infinite;
                transform-origin: 84px 93px;
            }
            .in-bottom {
                animation: rotate 15s linear infinite;
                transform-origin: 84px 93px;
            }
